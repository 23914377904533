import React, { useContext } from 'react';
import { LeaderBoardDataContext } from '../../../Contexts/LeaderBoardDataContext';
import LeaderBoardCard from '../../LeaderBoard/LeaderBoardCard';
const LeaderBoard = () => {
  const { consumerData, contributorData, loading, totalConsumerEntries, totalContributorEntries } = useContext(LeaderBoardDataContext);
  const sortedConsumerData = consumerData ? consumerData.slice(0, 3) : [];
  const sortedContributorData = contributorData ? contributorData.slice(0, 3) : [];

  const styles = {
    cardHeightLeaderboard: {
      height: '41.8vh'
    },
    cardHeightLeaderboardDesktop:{
       height: '28vh'
    },
    cardHeightLeaderboardSmallDesktop:{
      height: '15vh'
   }
  };

  return (
    <>
      
        <LeaderBoardCard
          sortedConsumerData={sortedConsumerData}
          sortedContributorData={sortedContributorData}
          consumerData={consumerData}
          contributorData={contributorData}
          totalConsumerEntries={totalConsumerEntries}
          totalContributorEntries={totalContributorEntries}
          styles={styles}
          loading={loading} 
        />
      
    </>
  );
};
 
export default LeaderBoard;