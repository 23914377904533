import { useContext,useEffect, useState } from 'react';
import { ApiHubCardDataContext } from '../../../Contexts/ApiHubCardDataContext';
import { LeaderBoardDataContext } from '../../../Contexts/LeaderBoardDataContext';


export const ApiHubTotalData = () => {
  const { apiResponse } = useContext(ApiHubCardDataContext);
  const {rank}=useContext(LeaderBoardDataContext);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (apiResponse) {
      setIsLoading(false); 
    }
  }, [apiResponse]);
  if (isLoading) {
    return []; 
  }

  return [
    {
      title: "Total User",
      count: apiResponse.data.userCount
    },
    {
      title: "Your Latest Rank",
      count: rank
    }
  ];
};
