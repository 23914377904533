import React from 'react';
import GlobalCard from '../GlobalCard/GlobalCard';
import LeaderBoardCardAppStudio from './LeaderBoard';
import { AppStudioTotalData } from './AppStudioTotalData';
import ReactAngularComponentCountGraph from './Graphs/ReactAngularComponentCountGraph';
import AppStudioGraphContainer from './AppStudioGraphContainer'; 
import ReactAngularRadarGraph from './Graphs/ReactAngularRadarGraph';
import ReactAngularComponentCountDoughnutGraph from './Graphs/ReactAngularComponentCountDoughnutGraph';


const AppStudio = () => {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-[6px] '>
        <div className='flex flex-col w-[75%] gap-[6px]'>
          <GlobalCard data={AppStudioTotalData()} />
          <div className='w-full'>
            <AppStudioGraphContainer header="Click Count Of React and Angular in App Studio" Component={ReactAngularComponentCountGraph} className="" />
          </div>
        </div>
        <div className='w-[25%]'>
          <LeaderBoardCardAppStudio />
        </div>
      </div>
      <div className='flex flex-row gap-[6px] w-full py-2 pb-4 '>
        <div className='w-[60%] rounded-lg shadow-lg'>
          <AppStudioGraphContainer header="App Studio Component Count Category Wise" Component={ReactAngularRadarGraph} className="" />
        </div>
        <div className='w-[40%] rounded-lg shadow-lg'>
        <AppStudioGraphContainer header="Count of Angular and React Count in App Studio" Component={ReactAngularComponentCountDoughnutGraph} className="" />
        </div>
      </div>
    </div>
  );
};

export default AppStudio;
