import React from 'react';
import LoginCard from '../../Components/Login/LoginCard';
import BackgroundImage from '../../Assets/Background.png'; 
import { useMsal } from '@azure/msal-react';

const LoginPage = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    
    instance.loginRedirect();
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen w-full text-white"
      style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between w-11/12">
        <div className="text-center ml-3 mb-8 md:mb-0">
          <p className="text-[52px] tracking-wider leading-tight m-0">
            <strong>UNIFIED DEV SUITE</strong>
          </p>
          <p className="text-xl tracking-wide leading-snug m-0">One stop shop for Application development</p>
        </div>
        <div className="md:pr-5">
          <LoginCard onLogin={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
