import React from 'react'
import UserIcon from '../../Assets/Icons/userIcon.png';
import flagRank from '../../Assets/Icons/flagRank.png'

const BoardFrameContributor = ({sortedData}) => {
  const trimName = (name) => {
    if (!name) return '';
    return name.split(' ').slice(0, 2).join(' ');
  };
  
    return (
      <div>
          {sortedData.map((user, index) => (
          <div key={index} className='flex flex-row justify-between items-center mb-2 border-b border-[#ebebeb]'>
            <div className='flex items-center'>
              <div>
                <img src={UserIcon} alt='user' className='w-[40px] h-[40px]' /> 
              </div>
              <span className='ml-2 text-[14px]'>{trimName(user.senderName)}</span>
            </div>
            <div className='bg-no-repeat bg-center bg-contain text-white text-sm w-[25px] h-[25px] text-center' 
              style={{ backgroundImage: `url(${flagRank})` }}>
              <span>{user.rank}</span>
            </div>
          </div>
        ))}
        </div>
    )
}

export default BoardFrameContributor