import React, { useState, useContext } from 'react';
import gotToIcon from './../../../Assets/Icons/GoToIcon.png';
import reviewIcon from './../../../Assets/Icons/reviewIcon.png';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ActiveButtonContext } from '../../../Contexts/ActiveButtonContext';
import FeedBackComponent from '../../FeedBack/FeedBackComponent';
import PopupSetUp from '../../FeedBack/PopUpSetUp';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';


const UpperPanel = () => {

  const { activeButton } = useContext(ActiveButtonContext);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { accounts,instance } = useMsal();
  const navigate = useNavigate();
  const handleLogout = () => {
    instance.logout().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    }).catch(e => {
      console.error(e);
    });
  };
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleFeedbackPopup = () => {
    setIsFeedbackOpen(!isFeedbackOpen);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <nav className='w-full'>
      <div className='bg-[#002746] justify-between py-2 pb-14 items-center flex flex-col'>
        <div className='flex px-6 justify-between w-full py-2 items-center'>
          <div className='justify-between items-center'>
            <div>
              <h3 className='text-white text-lg'>Dashboard</h3>
            </div>
            <div>
              <p className='text-[#0077CF] text-sm leading-5'>{activeButton}</p>
            </div>
          </div>
          <div className='flex gap-4 items-center'>
            <div className='text-white'>
              <p className='py-1'>Hi {accounts[0].name}</p>
            </div>
            <div className='flex items-center'>
              <Avatar {...stringAvatar(accounts[0].name)} sx={{ width: 30, height: 30 }} onClick={handleMenuOpen} />
              <ArrowDropDownIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={handleMenuOpen} />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ marginTop: 1, marginRight: 1 }}
              >
                
                <MenuItem onClick={handleMenuClose} sx={{ padding: 0 }}>
                  <button onClick={handleLogout} className="w-full text-black py-2 px-4">Logout</button>
                </MenuItem>
                
              </Menu>
            </div>
          </div>
        </div>
      
        <div className="bg-[#002746] w-full flex  top-0 justify-end gap-2 pr-6">
          <div className="flex lg:flex-row  gap-2 text-center">
            <div className="w-52">
              <button className="w-full text-white bg-[lightgrey] cursor-not-allowed font-bold py-2 px-4 rounded " disabled={true}>
                Be a contributor
              </button>
            </div>
            <div className="w-52">
              <button className="w-full bg-[#FFFFFF] text-black font-bold py-2 px-4 rounded flex items-center">
                <img src={gotToIcon} alt="goto" className="mr-2" />
               
                <span>Go to Playground</span>
              </button>
            </div>
            <div className="w-52">
              <button
                className="w-full text-white justify-center bg-transparent border border-[#C5C5C5] font-bold py-2 px-4 rounded flex items-center"
                onClick={toggleFeedbackPopup}
              >
                <img src={reviewIcon} alt="goto" className="mr-2" />
                Send Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
      {isFeedbackOpen && (
        <PopupSetUp onClose={toggleFeedbackPopup}>
          <FeedBackComponent />
        </PopupSetUp>
      )}
    </nav>
  );
};

export default UpperPanel;
