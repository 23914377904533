import { useState, useEffect } from 'react';

const useComponentData = () => {
    const [loading, setLoading] = useState(true);
    const [reactComponentCountData, setReactComponentCountData] = useState([]);
    const [angularComponentCountData, setAngularComponentCountData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [reactResponse, angularResponse] = await Promise.all([
                    fetch('https://prod.appstudioltts.com/api/react'),
                    fetch('https://prod.appstudioltts.com/api/angular')
                ]);

                const reactData = await reactResponse.json();
                const angularData = await angularResponse.json();
               
                

                setReactComponentCountData(reactData);
                setAngularComponentCountData(angularData);
            } catch (error) {
                console.error('Error fetching component count data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { loading, reactComponentCountData, angularComponentCountData };
};

export default useComponentData;
