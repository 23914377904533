import closeIcon from '../../Assets/Icons/close.svg'

const PopupSetUp = ({ onClose, children }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handlePopupClose = (e) => {
    
      onClose();
    
  };

  return (
    <div
      className="fixed inset-0 flex flex-row items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={handleOverlayClick}
    >
      
      <div className="bg-white rounded shadow-lg text-black border border-gray-600 relative ">
          <div className='bg-[#002746] p-4 text-white flex justify-between rounded-t-[0.18rem]'>
            <p>Send Feedback</p>
            <button onClick={handlePopupClose}><img src={closeIcon} alt='icon' className='w-7 h-7'/></button>
          </div>
        <div className="px-4 pt-4 xl:h-[70vh] 2xl:h-[50vh] overflow-y-scroll custom-scrollbar">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopupSetUp;
