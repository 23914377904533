import { useContext, useEffect, useState } from 'react';
import { ApiHubCardDataContext } from '../../../Contexts/ApiHubCardDataContext';

export const ApiHubCardData = () => {
  const { apiResponse } = useContext(ApiHubCardDataContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (apiResponse) {
    
      setIsLoading(false); 
    }
  }, [apiResponse]);

  

  if (isLoading) {
    return []; 
  }

  const ApiHubData = [
    {
      Title: 'All Projects',
      TitleValue: apiResponse.data.projectCount ?? 0,
      state: [
        { Title: 'Pending', Value: apiResponse.data.pendingProjects ?? 0 },
        { Title: 'Review', Value: apiResponse.data.reviewProjects ?? 0 },
        { Title: 'Approved', Value: apiResponse.data.approvedProjects ?? 0 },
        { Title: 'Rejected', Value: apiResponse.data.rejectedProjects ?? 0 }
      ]
    },
    {
      Title: "Total APIs",
      TitleValue: apiResponse.data.apiCount ?? 0,
      state: [
        { Title: 'Pending', Value: apiResponse.data.pendingApiCount ?? 0 },
        { Title: 'Review', Value: apiResponse.data.reviewApiCount ?? 0 },
        { Title: 'Approved', Value: apiResponse.data.approvedApiCount ?? 0 },
        { Title: 'Rejected', Value: apiResponse.data.rejectedApiCount ?? 0 }
      ]
    },
    {
      Title: "BU Admin",
      TitleValue: apiResponse.data.buAdminCount ?? 0,
      state: [
        { Title: 'Pending', Value: apiResponse.data.pendingUsersCount ?? 0 },
        { Title: 'Approved', Value: apiResponse.data.approvedUsersCount ?? 0 },
        { Title: 'Rejected', Value: apiResponse.data.rejectedUsersCount ?? 0 }
      ]
    }
  ];

  return ApiHubData;
};
