import { useMsal } from '@azure/msal-react';
import React, { createContext, useState, useEffect } from 'react';

// Create a context for the leaderboard data
export const LeaderBoardDataContext = createContext();

export const LeaderBoardDataProvider = ({ children }) => {
  const [consumerData, setConsumerData] = useState(null);
  const [contributorData, setContributorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [rank,setRank]=useState()
  const{accounts}=useMsal();

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch data from both endpoints concurrently using 'no-cors' mode
        const [consumerResponse, contributorResponse] = await Promise.all([
          fetch('https://prod.appstudioltts.com/api/auth'),
          fetch('https://prod.appstudioltts.com/api/rank/fetch-rank-data')
        ]);

       
        const consumerData = await consumerResponse.json();
        const contributorData = await contributorResponse.json();
        const userrank = contributorData.find(item => item.email === accounts[0]?.username)?.rank || 'N/A';
        setRank(userrank);
        console.log("Rank",rank)

        setConsumerData(consumerData);
        setContributorData(contributorData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accounts,rank]);

  // Calculate total entries and published count
  const totalConsumerEntries = consumerData ? consumerData.length : 0;
  const totalContributorEntries = contributorData ? contributorData.length : 0;
  const totalPublishedCount = contributorData
    ? contributorData.reduce((total, contributor) => total + (contributor.publishedCount || 0), 0)
    : 0;

  return (
    <LeaderBoardDataContext.Provider value={{ rank,consumerData, contributorData, loading, error, totalConsumerEntries, totalContributorEntries, totalPublishedCount }}>
      {children}
    </LeaderBoardDataContext.Provider>
  );
};

export default LeaderBoardDataProvider;
