import React from 'react';
import Chart from 'react-apexcharts';
import useComponentCountData from '../../../API/useComponentCountData';
// import useComponentData from './useComponentData';


const ReactAngularComponentCountDoughnutGraph = () => {
    const { loading, reactAngularComponentCountData } = useComponentCountData();

    if (loading) {
        return <div>Loading...</div>;
    }

    const labels = reactAngularComponentCountData.map((item) => item.Technology);

    const ComponentCount =  reactAngularComponentCountData.map((item) => item.count);


  const options = {
    chart: {
      type: 'donut',
    },
    labels: labels,
    colors: ['#002746', '#2DA2F9'],
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'], // Set the color to black
      },
      formatter: function (val, opts) {
        return opts.w.globals.series[opts.seriesIndex]; 
      },
      // enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%', //Size of the Doughnut Graph
          labels: {
            show: true,
            total:{
              showAlways: true,
              show: true,
            }
          },
        },
      },
    },
  };

  const series = ComponentCount;

  return (
    <div id="chart">
      <Chart options={options} series={series} type="donut" height={300}  />
    </div>
  );
};

export default ReactAngularComponentCountDoughnutGraph;
