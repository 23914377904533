import { useContext } from 'react';
import { LeaderBoardDataContext } from '../../Contexts/LeaderBoardDataContext';


export const AppStudioTotalData = () => {
  const { totalConsumerEntries,totalPublishedCount,rank  } = useContext(LeaderBoardDataContext);


  return [
    {
      title: "Total User",
      count: totalConsumerEntries
    },
    {
      title: "Total Component",
      count: totalPublishedCount 
    },
    {
      title: "Your Latest Rank",
      count: rank
    }
  ];
};
