import React, { useState } from 'react';
import Popup from './Popup'; // Adjust the import path as necessary

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <footer className="bg-[#002746] items-center text-white py-3 px-4 flex flex-row justify-between">
      <div className='flex flex-row justify-between w-[60%] text-sm'>
        <div>
          <p>© L&T Technology Services : All Rights Reserved.</p>
        </div>
        <div>
          <p>Unified Dev Suit v1.0</p>
        </div>
      </div>
      <div className='text-sm'>
        <button onClick={handleButtonClick}>Copyright & Terms</button>
      </div>
      {isPopupOpen && <Popup onClose={handleClosePopup} />}
    </footer>
  );
};

export default Footer;
