import React, { useContext, useEffect, useRef, useState } from 'react';
import closeIcon from '../../Assets/Icons/close.svg';
import dropdownIcon from '../../Assets/Icons/dropdown.svg'; // Add your dropdown icon here
import { LeaderBoardDataContext } from '../../Contexts/LeaderBoardDataContext';


const ConsumerTable = ({ onClose }) => {
  const { consumerData } = useContext(LeaderBoardDataContext);
  const closeButtonRef = useRef(null);
  const dropdownRef=useRef(null)

  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [businessUnitFilter, setBusinessUnitFilter] = useState('');
  const [deliveryUnitFilter, setDeliveryUnitFilter] = useState('');

  const [showUserTypeDropdown, setShowUserTypeDropdown] = useState(false);
  const [showBusinessUnitDropdown, setShowBusinessUnitDropdown] = useState(false);
  const [showDeliveryUnitDropdown, setShowDeliveryUnitDropdown] = useState(false);

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowUserTypeDropdown(false);
        setShowBusinessUnitDropdown(false);
        setShowDeliveryUnitDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (dropdown) => {
    setShowUserTypeDropdown(dropdown === 'userType' ? !showUserTypeDropdown : false);
    setShowBusinessUnitDropdown(dropdown === 'businessUnit' ? !showBusinessUnitDropdown : false);
    setShowDeliveryUnitDropdown(dropdown === 'deliveryUnit' ? !showDeliveryUnitDropdown : false);
  };

  const handleUserTypeSelect = (type) => {
    setUserTypeFilter(type);
    setShowUserTypeDropdown(false);
  };

  const handleBusinessUnitSelect = (unit) => {
    setBusinessUnitFilter(unit);
    setShowBusinessUnitDropdown(false);
  };

  const handleDeliveryUnitSelect = (unit) => {
    setDeliveryUnitFilter(unit);
    setShowDeliveryUnitDropdown(false);
  };

  const filteredData = consumerData.filter(consumer => {
    return (
      (userTypeFilter === '' || consumer.UserType === userTypeFilter) &&
      (businessUnitFilter === '' || consumer.DeliveryGroup === businessUnitFilter) &&
      (deliveryUnitFilter === '' || consumer.DeliveryUnit === deliveryUnitFilter)
    );
  });
  const handleAllfilter = () => {
    setUserTypeFilter('');
    setBusinessUnitFilter('');
    setDeliveryUnitFilter('');
  };

  const uniqueUserTypes = [...new Set(filteredData.map(consumer => consumer.UserType))];
  const uniqueBusinessUnits = [...new Set(filteredData.map(consumer => consumer.DeliveryGroup))];
  const uniqueDeliveryUnits = [...new Set(filteredData.map(consumer => consumer.DeliveryUnit))];

  return (
    <div className="fixed inset-0 flex pt-5 justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded shadow-lg text-black w-[90vw] h-[min-content] border border-gray-600 relative z-20">
        <div className="sticky top-0 bg-[#002746] text-white h-12 flex items-center justify-between px-2 shadow-lg z-10 mb-4">
          <h1>Consumer List</h1>
          <button 
            onClick={onClose} 
            aria-label="Close" 
            className="rounded-full hover:bg-[#70809087]" 
            ref={closeButtonRef}
          >
            <img src={closeIcon} alt="close" className="w-8 h-8" />
          </button>
        </div>
        <div className="px-4  h-[73vh] overflow-y-auto">
        <table  className="bg-white w-full border border-gray-200">
              <thead className=" text-white  border-b border-gray-200  z-10">
                <tr className="table-row bg-[#002746] border-b sticky top-[-1px] border-gray-200 " >
                  <th className="table-cell w-[5%] text-left p-2"><span className='font-bold'>Sl No</span></th>
                  <th className="table-cell text-left p-2"><span className='font-bold'>Name</span></th>
                  <th className="table-cell text-left p-2 w-[280px]"><span className='font-bold'>Mail</span></th>
                  <th className="table-cell text-left p-2 relative">
                    <span className='font-bold'>User Type</span>
                    <img 
                      src={dropdownIcon} 
                      alt="dropdown" 
                      className="inline-block ml-2 cursor-pointer w-4 h-4" 
                      onClick={() => handleDropdownClick('userType')} 
                    />
                    {showUserTypeDropdown && (
                      <div ref={dropdownRef} className="absolute text-black bg-white border mt-1 rounded shadow-lg z-10 h-auto overflow-y-auto">
                        <div className={`p-2 cursor-pointer ${userTypeFilter === '' ? 'bg-gray-200' : ''} font-normal`} onClick={() => handleAllfilter()}>All</div>
                        {uniqueUserTypes.map((type, index) => (
                          <div key={index} className={`p-2 cursor-pointer ${userTypeFilter === type ? 'bg-gray-200' : ''} font-normal`} onClick={() => handleUserTypeSelect(type)}>{type}</div>
                        ))}
                      </div>
                    )}
                  </th>
                  <th className="table-cell text-left p-2 relative">
                    <span className='font-bold'>Business Unit</span>
                    <img 
                      src={dropdownIcon} 
                      alt="dropdown" 
                      className="inline-block ml-2 cursor-pointer w-4 h-4" 
                      onClick={() => handleDropdownClick('businessUnit')} 
                    />
                    {showBusinessUnitDropdown && (
                      <div ref={dropdownRef} className="absolute text-black bg-white border mt-1 rounded shadow-lg z-10 max-h-[40vh] overflow-y-auto">
                        <div className={`p-2 cursor-pointer ${businessUnitFilter === '' ? 'bg-gray-200' : ''} font-normal`} onClick={() => handleAllfilter()}>All</div>
                        {uniqueBusinessUnits.map((unit, index) => (
                          <div key={index} className={`p-2 cursor-pointer ${businessUnitFilter === unit ? 'bg-gray-200' : ''} font-normal`} onClick={() => handleBusinessUnitSelect(unit)}>{unit}</div>
                        ))}
                      </div>
                    )}
                  </th>
                  <th className="table-cell text-left p-2 relative">
                    <span className='font-bold'>Delivery Unit</span>
                    <img 
                      src={dropdownIcon} 
                      alt="dropdown" 
                      className="inline-block ml-2 cursor-pointer w-4 h-4" 
                      onClick={() => handleDropdownClick('deliveryUnit')} 
                    />
                    {showDeliveryUnitDropdown && (
                      <div ref={dropdownRef} className="absolute text-black bg-white border mt-1 rounded shadow-lg z-10 max-h-[40vh] overflow-y-auto">
                        <div className={`p-2 cursor-pointer ${deliveryUnitFilter === '' ? 'bg-gray-200' : ''} font-normal`} onClick={() => handleAllfilter()}>All</div>
                        {uniqueDeliveryUnits.map((unit, index) => (
                          <div key={index} className={`p-2 cursor-pointer ${deliveryUnitFilter === unit ? 'bg-gray-200' : ''} font-normal`} onClick={() => handleDeliveryUnitSelect(unit)}>{unit}</div>
                        ))}
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((consumer, index) => (
                  <tr key={index} className="table-row border-b border-gray-200">
                    <td className="table-cell w-[5%] text-left p-2">{index + 1}</td>
                    <td className="table-cell text-left p-2">{consumer.UserName}</td>
                    <td className="table-cell text-left p-2 ">{consumer.UserMailID}</td>
                    <td className="table-cell text-left p-2">{consumer.UserType}</td>
                    <td className="table-cell text-left p-2">{consumer.DeliveryGroup}</td>
                    <td className="table-cell text-left p-2">{consumer.DeliveryUnit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
        <div className="sticky bottom-0 bg-white w-full flex justify-end p-2 rounded-b-[4px] gap-3" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px' }}>
          <button onClick={onClose} className="px-4 py-2 bg-[#002746] border-2 text-white rounded-md">
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsumerTable;
