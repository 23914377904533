import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const ApiHubCardDataContext = createContext();

export const ApiHubCardDataProvider = ({ children }) => {
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await fetch('https://api.devxidp.com/apihub/v0.1/admin/dashboard', {
          method: 'GET',
          headers: {
            'authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imp5b3RoaUBsdHRzLmNvbSIsImlhdCI6MTcyNDA0NjEwNCwiZXhwIjoxNzM5ODE0MTA0fQ.Pg2leKoFAhS-Sn1Uo7oBiIWqWHwH7405NHOWArz9qbw",
          }
        });
        const data = await response.json();
       
        setApiResponse(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ApiHubCardDataContext.Provider value={{ apiResponse }}>
      {children}
    </ApiHubCardDataContext.Provider>
  );
};
