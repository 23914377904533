import React from 'react';
import Card from './Card'; 
import CardSkeleton from './CardSkeleton';
import { ApiHubCardData } from './ApiHubCardData';
import GlobalCard from '../../GlobalCard/GlobalCard';
import { ApiHubTotalData } from './ApiHubTotalData';
import LeaderBoard from './LeaderBoard';
import LeaderBoardDataProvider from '../../../Contexts/LeaderBoardDataContext';

const Cards = () => {
  const apiHubTotalData = ApiHubTotalData();
  const apiHubCardData = ApiHubCardData();

  return (
    <div className='flex flex-row gap-[6px] w-full flex-grow'>
      <div className='w-[75%]'>
        <div className='flex flex-col gap-[6px] '>
          <div className="flex flex-wrap w-full">
            <GlobalCard data={apiHubTotalData} />
          </div>
          <div className="flex  gap-[6px]">
            {apiHubCardData.length > 0 ? (
              apiHubCardData.map((data, index) => (
                <Card key={index} data={data} />
              ))
            ) : (
              [...Array(3)].map((_, index) => (
                <CardSkeleton key={index} />
              ))
            )}
          </div>
        </div>
      </div>
      <div className='w-[25%]'>
      <LeaderBoardDataProvider>
        <LeaderBoard />
      </LeaderBoardDataProvider>
      </div>
    </div>
  );
};

export default Cards;
