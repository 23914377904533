import React, { useState ,useEffect} from 'react';
import useFeedbackDataApiCall from './useFeedbackDataApiCall';
import { useMsal } from '@azure/msal-react';



const feedbackdata = [
  { name: "General" },
  { name: "Unified Dev Suite" },
  { name: "UI Repo" },
  { name: "Api Repo" }
];


const FeedBackComponent = () => {
  const { feedbackData, loading, error, postFeedbackData } = useFeedbackDataApiCall();
  const{accounts}=useMsal();
  const [formValues, setFormValues] = useState({
    star_rate: 0,
    hoverRating: 0,
    category: '',
    message: ''
  });
  const [buttonDisable,setButtonDisable]=useState(true)
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(feedbackData);
  }, [feedbackData]);

  const handleStarClick = (index) => {
    setFormValues({ ...formValues, star_rate: index + 1 });
  };

  const handleStarMouseEnter = (index) => {
    setFormValues({ ...formValues, hoverRating: index + 1 });
  };

  const handleStarMouseLeave = () => {
    setFormValues({ ...formValues, hoverRating: 0 });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormValues = { ...formValues, [name]: value };
    setFormValues(updatedFormValues);
  
    if (updatedFormValues.category && updatedFormValues.message) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValues.category && formValues.message) {
      const newReview = {
        sendername:accounts[0].name,
        star_rate: formValues.star_rate,
        category: formValues.category,
        message: formValues.message
      };
      try {
        await postFeedbackData(newReview);
        setFormValues({
          star_rate: 0,
          hoverRating: 0,
          category: '',
          message: ''
        });
        setButtonDisable(true);
      } catch (err) {
        alert("Error submitting feedback");
      }
    } else {
      
      alert("Please fill out all fields.");
    }
  };

  return (
    <div className="p-4">
      <div className="bg-white p-6 rounded-lg">
        <div>
          <p className="text-lg font-semibold">We value your opinion and appreciate your feedback</p>
          <p className="mt-2">How would you rate the Unified Dev Suite?</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex mt-4">
            {[...Array(5)].map((star, index) => (
              <span
                key={index}
                className={`text-3xl cursor-pointer ${index < (formValues.hoverRating || formValues.star_rate) ? 'text-yellow-500' : 'text-gray-300'}`}
                onClick={() => handleStarClick(index)}
                onMouseEnter={() => handleStarMouseEnter(index)}
                onMouseLeave={handleStarMouseLeave}
                aria-label={`Rate ${index + 1} stars`}
              >
                ★
              </span>
            ))}
          </div>
          <div className="mt-4">
            <select
              className="w-full p-2 border border-gray-300 rounded-md"
              name="category"
              value={formValues.category}
              onChange={handleChange}
              required
              aria-label="Select category"
            >
              <option value="" disabled>Select category</option>
              {feedbackdata.map((item, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md"
              name="message"
              value={formValues.message}
              onChange={handleChange}
              placeholder="Enter your comments"
              required
              aria-label="Enter your comments"
            />
          </div>
          <div className="mt-4">
            <button
              disabled={buttonDisable}
              className={`w-full  text-white p-2 rounded-md ${buttonDisable ? 'bg-[lightgrey]':'bg-[#002746]'}`}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      
      <div className="mt-8 bg-white p-6 rounded-lg">
        <div>
          <p className="text-lg font-semibold">User Comments</p>
        </div>
        <div className="mt-4">
          {loading && <p>Loading...</p>}
          {error && <p>Error loading feedback data</p>}
          {!loading && !error && reviews.map((review, index) => (
            <div key={index} className="mb-4 p-4 border border-gray-200 rounded-md">
              <p className="mt-2"><strong>User:</strong> {review.sendername}</p>
              <div className="flex">
                {[...Array(5)].map((star, starIndex) => (
                  <span
                    key={starIndex}
                    className={`text-xl ${starIndex < review.star_rate ? 'text-yellow-500' : 'text-gray-300'}`}
                  >
                    ★
                  </span>
                ))}
              </div>
              <p className="mt-2"><strong>Category:</strong> {review.category}</p>
              <p className="mt-2"><strong>Comment:</strong> {review.message}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedBackComponent;
