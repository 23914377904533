import React from 'react';
import { HorizontalBarGraph } from '@ltts-dtp-appstudio/react-graphscharts';
import useComponentData from '../../../API/useComponentData';

const ReactAngularComponentCountGraph = () => {
    const { loading, reactComponentCountData, angularComponentCountData } = useComponentData();

    if (loading) {
        return <div>Loading...</div>;
    }

    const getCount = (data, category) => {
        const item = data.find(item => 
            (item.category && item.category === category) ||
            (item.Category && item.Category === category)
        );
        return item ? item.count : 0;
    };

    const graphData = [
        {
            label: "forms",
            value: getCount(angularComponentCountData, 'forms'),
            value2: getCount(reactComponentCountData, 'Forms'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "graph/charts",
            value: getCount(angularComponentCountData, 'graph/charts'),
            value2: getCount(reactComponentCountData, 'Graph_Charts'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "media_view",
            value: getCount(angularComponentCountData, 'media_view'),
            value2: getCount(reactComponentCountData, 'Media_View'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "resting_display",
            value: getCount(angularComponentCountData, 'resting_display'),
            value2: getCount(reactComponentCountData, 'Resting_Display'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "navigation",
            value: getCount(angularComponentCountData, 'navigation'),
            value2: getCount(reactComponentCountData, 'Navigation'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "generic_ui",
            value: getCount(angularComponentCountData, 'Generic_UI'),
            value2: getCount(reactComponentCountData, 'Generic_UI'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "authentication",
            value: getCount(angularComponentCountData, 'Authentication'),
            value2: getCount(reactComponentCountData, 'Authentication'),
            color1: "#002746",
            color2: "#2DA2F9"
        },
        {
            label: "project_structure",
            value: getCount(angularComponentCountData, 'Project_Structure'),
            value2: getCount(reactComponentCountData, 'Project_Strucure'),
            color1: "#002746",
            color2: "#2DA2F9"
        }
    ];

    const barStyle = {
        size: '8',
        height: '32vh'
    };

    return (
        <div>
            <HorizontalBarGraph graphstyle={barStyle} json={graphData} />
            {/* <p>Horigental Bar Graph Data</p> */}
        </div>
    );
};

export default ReactAngularComponentCountGraph;
