import React, { useContext } from 'react';
import { LeaderBoardDataContext } from '../../Contexts/LeaderBoardDataContext';
import LeaderBoardCard from '../LeaderBoard/LeaderBoardCard'
 
const LeaderBoard = () => {
  const { consumerData, contributorData, loading, totalConsumerEntries, totalContributorEntries } = useContext(LeaderBoardDataContext);
  const sortedConsumerData = consumerData ? consumerData.slice(0, 5) : [];
  const sortedContributorData = contributorData ? contributorData.slice(0, 5) : [];
  const styles = {
    cardHeightLeaderboard: {
      height: '100%'
    },
    cardHeightLeaderboardDesktop:{
      height: '100%'
   }
  };
  return (
    <LeaderBoardCard
      styles={styles.cardHeightLeaderboard}
      sortedConsumerData={sortedConsumerData}
      sortedContributorData={sortedContributorData}
      consumerData={consumerData}
      contributorData={contributorData}
      loading={loading}
      totalConsumerEntries={totalConsumerEntries}
      totalContributorEntries={totalContributorEntries}
    />
  );
};
 
export default LeaderBoard;