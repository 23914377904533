import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CardSkeleton = () => {
  return (
    <div className="border bg-white rounded-lg flex flex-1 shadow-lg" >
      <div className='flex justify-between py-6 px-4 shadow-md items-center'>
        <Skeleton width={100} height={20} />
        <Skeleton width={50} height={20} />
      </div>
      <div className='flex flex-row justify-between py-[1.36rem] px-4 rounded-b-lg'>
        {[...Array(3)].map((_, index) => (
          <div 
            key={index} 
            className={`flex flex-col justify-between ${index !== 2 ? 'border-r border-gray-300' : ''} pr-[22px]`}
          >
            <Skeleton width={50} height={20} className='mx-auto' />
            <Skeleton width={30} height={20} className='mx-auto mt-2' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSkeleton;
