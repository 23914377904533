import React from 'react'
import UserIcon from '../../Assets/Icons/userIcon.png';


const BoardFrameConsumer = ({sortedData}) => {
 
  const trimName = (name) => {
    if (!name) return '';
    return name.split(' ').slice(0, 2).join(' ');
  };
  return (
    <div>
        {sortedData.map((user, index) => (
        <div key={index} className='flex flex-row justify-between items-center mb-2 border-b border-[#ebebeb]'>
          <div className='flex items-center gap-4'>
            <div>
              <img src={UserIcon} alt='user' className='w-[40px] h-[40px]' /> 
            </div>
            <span className='ml-2 text-[14px]'>{trimName(user.UserName)}</span>
          </div>
          
        </div>
      ))}
      </div>
  )
}

export default BoardFrameConsumer
