import React from 'react';
import Cards from './Cards/Cards';
// import GlobalGraph from './Graphs/GlobalGraph';

import { ApiHubCardDataProvider } from '../../Contexts/ApiHubCardDataContext';



const ApiHub = () => {
  
 

  return (
    <div className="flex flex-col">
      <ApiHubCardDataProvider>
        <Cards />
      </ApiHubCardDataProvider>
      <div className="overflow-y-auto mt-4 shadow-lg h-screen">
      <iframe title="myFrame" className="w-full h-screen" src='https://elk.devxidp.com/app/dashboards#/view/b9b0df77-3dfa-4921-a291-1cc6dc72660a?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:60000),time:(from:now%2Fd,to:now%2Fd))&show-time-filter=true&hide-filter-bar=true'></iframe>
      </div>
    </div>
  );
};

export default ApiHub;

