import React from 'react';
import feedbackImg from "../../Assets/helpImages/Feedback.png"
import app_studio from "../../Assets/helpImages/App_studio.png"
import consumer_list from "../../Assets/helpImages/consumer_list.png"
import contributor_list from "../../Assets/helpImages/contributor_list.png"
import api_hub from "../../Assets/helpImages/api_hub.png"

const UnifiedDevSuiteOverview = () => {
  return (
    <div className="p-4">
      <div className="bg-white rounded-lg mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#002746]">Overview</h2>
        <p>The Unified Dev Suite provides a cohesive platform that combines reusable frontend components and robust backend APIs, enabling developers to quickly integrate tested, customizable resources into their projects. Currently, we are using App Studio and API Hub in the Unified Dev Suite project, promoting efficiency, reusability, and seamless collaboration for both frontend and backend development.</p>
        <p>The Unified Dev Suite enhances development efficiency through a component-driven architecture and an API-first approach. The main aim of the project is to provide reusable components and an API-first architecture.</p>
      </div>

      <div className="bg-white flex gap-4 flex-col  rounded-lg mb-8">
        <h2 className="text-2xl font-semibold  text-[#002746]">App Studio Community Portal</h2>
        <p>The App Studio Community Portal allows developers to access and manage the frontend components while tracking usage metrics and community contributions. The App Studio Community Portal Dashboard Page displays the following features:</p>
        <div className='flex justify-center'>
          <img src={app_studio} alt='app_studio' className='w-[90%] h-[80%] shadow-xl'/>
        </div>
        <ul className="list-disc list-inside">
          <li><strong >Total Data:</strong> Displays the volume of data used by App Studio components across projects.</li>
          <li><strong >Total Components:</strong> Shows the count of all available components.</li>
          <li><strong >Latest Rank Data:</strong> Displays your most recent position in the leaderboard based on component contributions and usage.</li>
          <li><strong >Leaderboard Category:</strong> Provides user data to display the board, including consumers and contributors (developers who add new components).</li>
        </ul>
        <div className='flex flex-row justify-center gap-4'>
          <img src={consumer_list} alt='consumer list' className='w-[45%] shadow-xl'/>
          <img src={contributor_list} alt='contributor list' className='w-[45%] shadow-xl'/>
        </div>
        <p>The graphs show the number of components using Angular or React in the App Studio Community Portal dashboard.</p>
      </div>

      <div className="bg-white flex gap-4 flex-col rounded-lg mb-8">
        <h2 className="text-2xl font-semibold  text-[#002746]">API Hub</h2>
        <p>The API Hub tracks the total volume of data consumed through API integration and displays the cumulative count of API requests made across projects. The API Hub Dashboard includes the following features:</p>
        <div className='flex justify-center'>
          <img src={api_hub} alt='app_studio' className='w-[90%] h-[80%] shadow-xl'/>
        </div>
        <ul className="list-disc list-inside">
          <li><strong >Total Data Usage:</strong> Tracks the total volume of data consumed through API integration.</li>
          <li><strong >Total API Hits:</strong> Displays the cumulative count of API requests made across projects.</li>
          <li><strong >Latest Rank:</strong> Reflects your current rank in terms of API usage and contributions.</li>
          <li><strong >Leaderboard:</strong> Ranks consumers and contributors based on their activity, similar to the App Studio.</li>
          <li><strong >All Project APIs:</strong> Lists all available APIs across different projects, offering visibility and quick access to each API.</li>
          <li><strong >BU Admin:</strong> Provides metrics specific to Business Unit (BU) admins for monitoring API usage within their divisions.</li>
          <li><strong >Graphs API:</strong> Allows for quick integration of backend services, making data retrieval and storage in MongoDB efficient and centralized.</li>
        </ul>
      </div>

      <div className="bg-white flex gap-4 flex-col rounded-lg mb-8">
        <h2 className="text-2xl font-semibold  text-[#002746]">Be a Contributor</h2>
        <p>The Be a Contributor button enables users to become part of the Unified Dev Suite’s development community. Upon clicking the button, a dialog box appears prompting the user to provide necessary details and agree to terms and conditions.</p>
        <h3 className="text-xl font-semibold  text-[#002746]">Terms and Conditions:</h3>
        <ul className="list-disc list-inside">
          <li>A new branch must be created in the Azure repository with the naming convention.</li>
          <li>Components must operate as standalone applications, meeting all project requirements.</li>
          <li>Inputs for each component must come from a JSON file, and any JSON changes must reflect dynamically in the output.</li>
          <li>Contributions must not alter or impact existing components, libraries, or playground functionality.</li>
          <li>The user must agree to these terms and conditions before proceeding.</li>
        </ul>
        <p>After submitting their details, the user becomes eligible to contribute components to the Unified Dev Suite. Admin will review the submission and grant contributor access as necessary.</p>
      </div>

      <div className="bg-white flex gap-4 flex-col rounded-lg mb-8">
        <h2 className="text-2xl font-semibold  text-[#002746]">Send Feedback</h2>
        <p>The send feedback feature allows users to provide valuable input and suggestions regarding various aspects of the Unified Dev Suite. Users can provide a star rating for their experience.</p>
        <div className="justify-center flex"><img src={feedbackImg} alt='FeedBack img' className='h-96 w-96 shadow-xl'/></div>
        <ul className="list-disc list-inside">
          <li><strong >General:</strong> For comments related to the overall Unified Dev Suite platform. Feedback in this category will be stored under “General Feedback”.</li>
          <li><strong >Unified Dev Suite:</strong> For feedback specific to the Unified Dev Suite’s features and functionality.</li>
          <li><strong >App Studio:</strong> For comments or suggestions related to the App Studio dashboard or its components.</li>
          <li><strong >API Hub:</strong> For feedback about API-related functionalities in the API Hub dashboard.</li>
        </ul>
        <p>When a category is selected and a comment is added, it will appear under the respective user comments section.</p>
      </div>
      <div className="bg-white flex gap-4 flex-col rounded-lg mb-8">
        <h2 className="text-2xl font-semibold  text-[#002746]">UDS Roadmap</h2>
        <p>We are adding additional features include development on customer cloud environments, which ensures secure and compliant integration and built-in copilot tool designed to optimized development speed. These elements contribute to a streamlined workflow, offering tested, verified components that developers can leverage for faster and more efficient project completion. The Unified Dev Suite ultimately stands as an adaptable, robust environment, fostering efficiency and innovation in development through reusability, collaboration and integration.</p>
      </div>
    </div>
  );
};

export default UnifiedDevSuiteOverview;
