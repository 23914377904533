import React, { useContext, useState } from 'react';
 
import ConsumerIcon from '../../Assets/Icons/ConsumerIconActive.png';
import ConsumerIconInActive from '../../Assets/Icons/ConsumerIconInActive.png';
import ContributorIcon from '../../Assets/Icons/ContributorIconActive.png';
import ContributorIconInActive from '../../Assets/Icons/ContributorIconInActive.png';
import BoardFrameConsumer from './BoardFrameConsumer';
import BoardFrameContributor from './BoardFrameContributor';
import LeaderBoardCardSkeleton from './LeaderBoardCardSkeleton';
import ConsumerTable from './ConsumerTable';
import ContributorTable from './ContributorTable';
import { ActiveButtonContext } from '../../Contexts/ActiveButtonContext';
 
const LeaderBoardCard = ({
  sortedConsumerData,
  sortedContributorData,
  consumerData,
  contributorData,
  loading,
  totalConsumerEntries,
  totalContributorEntries,
  styles
}) => {
  const [activeButton, setActiveButton] = useState('Consumer');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { activeUrl } = useContext(ActiveButtonContext);

  const cardHeightClass = styles?.cardHeightLeaderboard?.height === '41.8vh' ? 'xl:h-[41.8vh]' : 'xl:h-[60vh]';
  const desktopCardHeightClass = styles?.cardHeightLeaderboardDesktop?.height === '28vh' ? '2xl:h-[28vh]' : '2xl:h-[50.5vh]';
  const smallScreenCardHeightClass = styles?.cardHeightLeaderboardSmallDesktop?.height === '15vh' ? 'lg:h-[26vh]' : 'lg:h-[47vh]';
  const handleViewAllClick = () => {
    setIsPopupOpen(true);
  };
 
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
 
  if (loading) return <LeaderBoardCardSkeleton />;
 
  return (
    <div className={`flex flex-col border bg-white rounded-md shadow-lg w-full ${cardHeightClass} ${desktopCardHeightClass} ${smallScreenCardHeightClass}`}>
      <div className='flex flex-row justify-between px-5 pt-4'>
        <div><h1><strong>Leaderboard</strong></h1></div>
      </div>
      <div className='flex flex-row mt-2'>
        <div className='flex-1'>
          <button
            className={`flex items-center justify-center w-full space-x-2 ${activeButton === 'Consumer' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveButton('Consumer')}
          >
            <img src={activeButton === 'Consumer' ? ConsumerIcon : ConsumerIconInActive} alt='Consumer' />
            <span className={`text-[14px] ${activeButton === 'Consumer' ? 'text-blue-500' : 'text-gray-500'}`}>
              Consumer({totalConsumerEntries})
            </span>
          </button>
        </div>
        <div className='flex-1'>
          <button
            className={`flex items-center justify-center w-full space-x-2 ${activeButton === 'Contributor' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveButton('Contributor')}
          >
            <img src={activeButton === 'Contributor' ? ContributorIcon : ContributorIconInActive} alt='Contributor' />
            <span className={`text-[14px] ${activeButton === 'Contributor' ? 'text-blue-500' : 'text-gray-500'}`}>
              Contributor({totalContributorEntries})
            </span>
          </button>
        </div>
      </div>
      <div className={`px-5 pt-3 overflow-hidden `}>
        {activeButton === 'Consumer' ? (
          <BoardFrameConsumer sortedData={consumerData} />
        ) : (
          <BoardFrameContributor sortedData={contributorData} />
        )}
      </div>
      <div className={`flex justify-center my-3 ${activeUrl === 'ui_repo' ? '2xl:h-[60px] my-4' : '2xl:h-[34px] my-2'}`}>
        <button className='text-[#002746]' onClick={handleViewAllClick}>View all</button>
      </div>
      {isPopupOpen && (
        activeButton === 'Consumer' ? (
          <ConsumerTable data={consumerData} onClose={handleClosePopup} topThree={sortedConsumerData} />
        ) : (
          <ContributorTable data={contributorData} onClose={handleClosePopup} topThree={sortedContributorData} />
        )
      )}
    </div>
  );
}
 
export default LeaderBoardCard;