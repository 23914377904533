import { useState, useEffect } from 'react';

const useComponentCountData = () => {
    const [loading, setLoading] = useState(true);
    const [reactAngularComponentCountData, setReactAngularComponentCountData] = useState([]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [reactAngularResponse] = await Promise.all([
                    fetch('https://prod.appstudioltts.com/api/linkstateupdate/component-counts')
                ]);

                const componentCountData = await reactAngularResponse.json();
               
                setReactAngularComponentCountData(componentCountData)
                
            } catch (error) {
                console.error('Error fetching component count data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { loading, reactAngularComponentCountData };
};

export default useComponentCountData;
