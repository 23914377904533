import { useEffect, useState } from "react";

const usePublishedComponent = () => {
    const [loading, setLoading] = useState(true);
    const [publishedComponent, setPublishedComponent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://prod.appstudioltts.com/api/linkstateupdate/published-components');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                
                    setPublishedComponent(data);
               
                
            } catch (error) {
                console.error('Error fetching component count data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { loading, publishedComponent };
}

export default usePublishedComponent;
