import React from 'react';
import Loader from "react-js-loader";

const PublishedComponentList = ({ loading, publishedComponent }) => {
    
    const color="#002746"

    if (loading) {
       

        return (
            <div className="overflow-auto max-h-96">
                <div className={"item"}>
                    
                    <Loader type="spinner-default" bgColor={color} color={color} title={"Loading"} size={100} />
                </div>
            </div>
        );
    }

    return (
        <div className="overflow-auto max-h-96">
            <table className='table-fixed w-full rounded-t-lg'>
                <thead className='p-5'>
                    <tr className='sticky top-0 bg-[#002746] text-white rounded-t-lg '>
                        <th className='text-left w-16 p-2'>ID</th>
                        <th className='text-left p-2'>Component</th>
                        <th className='text-left w-32 p-2'>Technology</th>
                        <th className='text-left p-2'>Contributor</th>
                    </tr>
                </thead>
                <tbody>
                    {publishedComponent.map((component, index) => (
                        <tr key={index} className='border-b border-gray-200'>
                            <td className='p-2'>{index + 1}</td>
                            <td className='p-2'>{component.ComponentName}</td>
                            <td className='p-2'>{component.Technology}</td>
                            <td className='p-2'>{component.SenderName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PublishedComponentList;
