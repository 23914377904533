
const msalConfig = {
    auth: {
      clientId: "f9d0e101-7342-42e8-8b1e-090941e6e4b3",
      authority: 'https://login.microsoftonline.com/df7b3572-e484-4fcf-a072-6edd5d73f11f',
      redirectUri: 'https://udscommunitymain-cdarcna6cdcsgzan.southindia-01.azurewebsites.net/dashboard/ui_repo',
      //redirectUri: 'http://localhost:3000/dashboard/ui_repo',
      postLogoutRedirectUri: "https://udscommunitymain-cdarcna6cdcsgzan.southindia-01.azurewebsites.net",
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  };
  
  export default msalConfig;
  