import { useState, useEffect } from 'react';

const useFeedbackDataApiCall = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getFeedbackData = async () => {
    try {
      const response = await fetch('https://dev.appstudioltts.com/api/udsfeedback/view');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFeedbackData(data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const postFeedbackData = async (feedback) => {
    try {
      const response = await fetch('https://dev.appstudioltts.com/api/udsfeedback/review', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedback),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  useEffect(() => {
    getFeedbackData();
  }, []);

  return { feedbackData, loading, error, postFeedbackData };
};

export default useFeedbackDataApiCall;
