import React from 'react'

const AppStudioGraphContainer = ({ header, Component }) => {
  return (
    <div className='flex flex-col bg-white shadow-lg rounded-lg overflow-hidden border border-[#ccd4da]  h-full'>
      <div className='bg-[#EDF0F2] px-2 py-3 font-semibold rounded-t-lg text-sm border-b border-[#ccd4da]'>{header}</div>
      <div className='p-4 h-full'>
        <Component />
      </div>
    </div>
  )
}

export default AppStudioGraphContainer