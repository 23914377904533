import React from 'react';
import ProfileImg from '../../Assets/Icons/Profile.png'; 

const LoginCard = ({ onLogin }) => {
  return (
    <div className="bg-[#d6dbdf] rounded-[8px] border-2 border-white p-9 sm:p-10 relative flex flex-col items-center text-black">
      <img src={ProfileImg} alt="profile" className="w-16 h-16 absolute -top-8 left-1/2 transform -translate-x-1/2" />
      <h1 className="font-bold text-xl sm:text-2xl leading-8 sm:leading-10 font-gilroy">Welcome!</h1>
      <button 
        type="submit" 
        className="mt-4 px-4 sm:px-5 py-2 bg-[#0C3867] text-white rounded-[4px] border border-white text-base sm:text-lg" 
        onClick={onLogin}
      >
        Login With Outlook credentials
      </button>
    </div>
  );
};

export default LoginCard;
