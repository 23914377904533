import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useNavigate } from 'react-router-dom';
import LoginPage from './Pages/LoginPage/LoginPage';
import DashBoardPage from './Pages/DashBoardPage/DashBoardPage';
import './App.css';
import LeaderBoardDataProvider from './Contexts/LeaderBoardDataContext';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const ValidDashboardPage = () => {
  const { activeUrl: paramUrl } = useParams();
  const navigate = useNavigate();

  const validUrls = useMemo(() => ['ui_repo', 'api_repo', 'help'], []);

  useEffect(() => {
    if (!validUrls.includes(paramUrl)) {
      navigate('/');
    }
  }, [paramUrl, navigate, validUrls]);

  return validUrls.includes(paramUrl) ? <DashBoardPage /> : null;
};

const App = () => {
  const renderDashboardPage = () => {
    return (
      <>
        <AuthenticatedTemplate>
          <ValidDashboardPage />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Navigate to="/" />
        </UnauthenticatedTemplate>
        {/* <ValidDashboardPage /> */}
      </>
    );
  };

  return (
    <LeaderBoardDataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/dashboard" element={<Navigate to="/dashboard/ui_repo" />} />
          <Route path="/dashboard/:activeUrl" element={renderDashboardPage()} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </LeaderBoardDataProvider>
  );
};

export default App;
