import React, { createContext, useState } from 'react';

const ActiveButtonContext = createContext();

const ActiveButtonProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState('UI Repo');
  const [activeUrl, setActiveUrl] = useState('ui_repo');

  return (
    <ActiveButtonContext.Provider value={{ activeButton, setActiveButton,activeUrl,setActiveUrl }}>
      {children}
    </ActiveButtonContext.Provider>
  );
};

export { ActiveButtonContext, ActiveButtonProvider };
